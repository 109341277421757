<template>
  <div class="yongdian">
    <div class="title">用电控制</div>
    <div class="yongdian-body">
      <div class="item" v-for="(item, index) in oData" :key="index">
        <div class="item-t">{{ item.name }}</div>
        <div class="item-b">电流:{{ item.value }}A</div>
      </div>
    </div>
  </div>
</template>

<script>
import { wechatInteractiveRecordUrl } from "./api";
export default {
  name: "yongdian",
  data() {
    return {
      oData: [],
      axiosHeader: {
        "Cockpit-Auth":
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjkyMjMzNzIwMzY4NTQ3NzU4MDcsInBob25lIjoiMTU5MTIzNDU2NzgiLCJ1c2VybmFtZSI6ImFkbWluX3lkIn0.K5cNEP6h76OBqHYSyYqHviXPJrPSNkbs2cxEgG-x0tA",
      },
    };
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    async getRecord() {
      let res = await this.$axios.get(`${wechatInteractiveRecordUrl}`, {
        headers: this.axiosHeader,
      });
      if (res.code == 0) {
        this.oData = res.data;
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.yongdian {
  .title {
    text-align: center;
    font-size: 30px;
    line-height: 80px;
  }
  .yongdian-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    .item {
      width: 180px;
      flex-shrink: 0;
      padding-top: 1px;
      height: 180px;
      background: url("./img/dian.png") no-repeat;
      background-size: 100% 100%;
      font-size: 30px;
      text-align: center;
      .item-t {
        margin-top: 60px;
      }
    }
  }
}
</style>
